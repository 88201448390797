import axios from 'axios';
import { axiosInstance } from '../api';
import { errorHandler } from '../interceptors';
const BASE_PATH = '/candidate';

export let candidateWithdrawAnApplication = async (sessionID, reason, type) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/candidate_withdraw_application', {
            session_id: sessionID,
            reason: reason,
            type: type,
        })
        return value;
    } catch (error) {
        return error
    }
    
}