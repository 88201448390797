import React, { useState, useEffect } from 'react';
import { Row, Col, ProgressBar, Container, Image, Button, Alert } from 'react-bootstrap';
import styles from './interview.module.scss';

import { getCandidateDetailsRSVP, setCandidateRSVP, getJoinCandidateDetails, requestToAdmitInterview } from '../../services/util/interviews';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import ReactGA from 'react-ga';
import { CustomButton } from '../shared/customButton'
import { CustomDropdownConfirmationBox } from '../shared/customDropDownConfirmationBox';
import { CustomCandidateWaitingToJoinInterviewPopUpBox } from '../shared/CustomCandidateWaitingToJoinInterviewPopUpBox';
import { CustomMessagePopUpBox } from '../shared/customMessagePopUpBox';
import { CustomConfirmationModal } from '../shared/customConfirmationModal';
import { CustomAlertBox } from '../shared/customAlertBox';
import InterviewBody from './InterviewBody';
import { ThemeProvider } from 'styled-components';
import {
    MeetingProvider,
    darkTheme
} from 'amazon-chime-sdk-component-library-react';
import { Helmet } from "react-helmet";
import { candidateInterviewRejectionReasonsArray } from './responseArray';


const Interview = () => {
    let { id } = useParams();

    //getting query parameter value
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let isStarted = (params.get('is_started') == 'null') ? null : params.get('is_started');

    let [primaryColor, setPrimaryColor] = useState('');
    let [rejectResponse, setRejectResponse] = useState(false);
    let [approveResponse, setApproveResponse] = useState(false);
    let [rsvpResponse, setRSVPResponse] = useState({
        responseState: false,
        responseType: -1,
        message: ''
    })
    let [interviewDetails, setInterviewDetails] = useState();
    let [isLoading, setIsLoading] = useState(false);
    const [Rejectmodal, setRejectModal] = useState({
        isOpen: false,
        value: ''
    });
    const [modal, setModal] = useState({
        isOpen: false,
        value: ''
    });

    const [showInterviewody, setShowInterviewBody] = useState(false);

    const [loadingDeviceTest, setLoadingDeviceTest] = useState();
    const [loadingJoinInterview, setLoadingJoinInterview] = useState();
    const [deviceAccessError, setDeviceAccessError] = useState();
    const [showDeviceAccessErrorPopUp, setShowDeviceAccessErrorPopUp] = useState(false);
    const [showDeviceAccessSuccessPopUp, setShowDeviceAccessSuccessPopUp] = useState(false);
    const [showInterviewIsNotStartedPopUp, setShowInterviewIsNotStartedPopUp] = useState(false);
    const [showJoinInterviewErrorPopUp, setShowJoinInterviewErrorPopUp] = useState(false);
    const [showInterviewOverPopUp, setShowInterviewOverPopUp] = useState(false);
    const [joinMeetingData, setJoinMeetingData] = useState(false);
    let [isWaitingToJoinInterview,setIsWaitingToJoinInterview] = useState(false);

    let [showMeetingEndedDueToExternalLinkPopup, setShowMeetingEndedDueToExternalLinkPopup] = useState(false);
    let [showJoinMeetingEndedDueToInterviewerEndMeetingPopup, setShowJoinMeetingEndedDueToInterviewerEndMeetingPopup] = useState(false);


    // constraints object to check video/audio permissions in browser
    const constraints = window.constraints = {
        audio: true,
        video: true
    };

    // check if browser permissions enabled to access camera and mic
    const CheckDeviceAccess = (check_and_join_meeting) => {

        if (check_and_join_meeting) {
            setLoadingJoinInterview(true);
        }
        else {
            setLoadingDeviceTest(true);
        }
        navigator.mediaDevices.getUserMedia(constraints).then(function success(stream) {
            // if user clicked Join interview option
            if (check_and_join_meeting) {
                joinMeeting();
            } else { //else, just show the permission ok message
                setShowDeviceAccessSuccessPopUp(true)
                setLoadingDeviceTest(false);
            }

        }).catch(function (err) {
            if (err.name == "NotFoundError" || err.name == "DevicesNotFoundError") {
                setDeviceAccessError("Camera or Microphone not found");
                setShowDeviceAccessErrorPopUp(true);
            } else if (err.name == "NotReadableError" || err.name == "TrackStartError") {
                setDeviceAccessError("Microphone or Camera already in use by a different application ");
                setShowDeviceAccessErrorPopUp(true);
            } else if (err.name == "NotAllowedError" || err.name == "PermissionDeniedError") {
                setDeviceAccessError("Microphone or Camera permissions denied in browser.");
                setShowDeviceAccessErrorPopUp(true);
            } else {
                setDeviceAccessError("Failed to connect with Microphone or Camera. Please try again later");
                setShowDeviceAccessErrorPopUp(true);
            }
            setLoadingDeviceTest(false);
        });
    }

    const closeDeviceAccessTestPopups = () => {
        setShowDeviceAccessSuccessPopUp(false);
        setShowDeviceAccessErrorPopUp(false);
        setLoadingJoinInterview(false);
        setDeviceAccessError(null)
    }
    const closeWaitingPopups =() => {
        setIsWaitingToJoinInterview(false);
    }


    const openJoinInterviewErrorPopup = () => {
        setShowJoinInterviewErrorPopUp(true);
    }

    const closeJoinInterviewErrorPopup = () => {
        setShowJoinInterviewErrorPopUp(false);
        setShowMeetingEndedDueToExternalLinkPopup(false);
        window.location.reload();
    }

    const triggerAlternativeMeetingSharedAcknowledgementPopup = async () => {
        setShowMeetingEndedDueToExternalLinkPopup(true);
    }

    const alternativeMeetingSharedAcknowledgement = () => {
        window.location.reload();
    }

    const joinMeeting = async () => {
       
        
        // Fetch the meeting and attendee data from your server
        let respond = await getJoinCandidateDetails(id);
        if (respond.success === true && respond.data.attendee && respond.data.meeting) {

            let joinData = {
                meetingInfo: respond.data.meeting,
                attendeeInfo: respond.data.attendee
            };
            setJoinMeetingData(joinData)
            setShowInterviewBody(true);

        } else if (respond.success === true && respond.data && respond.data.already_ended_due_to_external_meeting) {
            triggerAlternativeMeetingSharedAcknowledgementPopup();
        } else if (respond.success === true && !respond.data.is_interview_started && !respond.data.is_interview_expired  && !respond.data.is_interviewer_end_meeting) {

            setShowInterviewIsNotStartedPopUp(true);
            setLoadingJoinInterview(false)
        }
        else if (respond.success === true && !respond.data.is_interview_started && respond.data.is_interview_expired) {
            setShowInterviewOverPopUp(true);
            setLoadingJoinInterview(false);
        }
        else if(respond.success === true && respond.data.is_interviewer_end_meeting){
            setShowJoinMeetingEndedDueToInterviewerEndMeetingPopup(true);
        }
        else {
            setLoadingJoinInterview(false);
            openJoinInterviewErrorPopup()
        }
    };


    //on submit rsvp
    const onSubmitRSVPResponse = async (is_can_attend, cannot_attend_reason) => {

        if (is_can_attend) {
            setIsLoading(true)
            let values = await setCandidateRSVP(id, 1);
            if (values.success === true) {
                setIsLoading(false)
                setRSVPResponse({
                    responseState: true,
                    responseType: 1
                })
                setRejectModal({
                    isOpen: false,
                    value: ''
                })
                setModal({ isOpen: false, value: '' })
            } else {
                setIsLoading(false)
            }
        } else {
            setIsLoading(true);
            let reason_string = candidateInterviewRejectionReasonsArray[cannot_attend_reason].label;
            let values = await setCandidateRSVP(id, -1, reason_string);
            if (values.success === true) {
                setIsLoading(false)
                setRSVPResponse({
                    responseState: true,
                    responseType: -1,
                    message: reason_string
                })
                setRejectModal({
                    isOpen: false,
                    value: ''
                })
                setModal({ isOpen: false, value: '' })
            } else {
                setIsLoading(false)
            }
        }

    }


    const changeApprove = () => {
        setRejectResponse(false);
        setApproveResponse(true);
    }

    const changeReject = () => {
        setApproveResponse(false);
        setRejectResponse(true);

    }

    const onSelectNotAttendBtn = (value) => {
        setRejectModal({
            isOpen: true,
            value: value
        })
    }

    const onSelectAttendTab = (value) => {
        setModal({
            isOpen: true,
            value: value
        })
    }

    const loadInterviewUserDetails = async () => {
        let value = await getCandidateDetailsRSVP(id);
        if (value.success === true) {
            setRSVPResponse({
                responseState: (value.data.interview_details.candidate_rsvp === 0) ? false : true,
                responseType: value.data.interview_details.candidate_rsvp,
                message: (value.data.interview_details.candidate_rsvp === -1) ? value.data.interview_details.candidate_rsvp_message : ''
            })
            setInterviewDetails(value.data.interview_details)
            setPrimaryColor(value.data.interview_details.primary_color ? value.data.interview_details.primary_color : '#24859a')
        }
    }

    useEffect(() => {
        loadInterviewUserDetails();
    }, [])

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
        let pageHit = `/app/candidate/interview`;
        ReactGA.initialize(`${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`);
        ReactGA.pageview(pageHit);
    }, [primaryColor]);

    return (
        <div>
            <Helmet>
                <title>Candidate Interview | HireTrace</title>
            </Helmet>
            {/* RSVP as "I Attend" confirmation popup */}
            <CustomConfirmationModal
                show={modal.isOpen}
                cancelIconclick={() => { setModal({ isOpen: false, value: '' }); setApproveResponse(false); setRejectResponse(false) }}
                onSecondaryBtnClick={() => { setModal({ isOpen: false, value: '' }); setApproveResponse(false); setRejectResponse(false) }}
                onPrimaryBtnClick={() => onSubmitRSVPResponse(true, null)}
                isSecondaryBtnDisabled={isLoading === true ? true : false}
                isPrimaryBtnDisabled={isLoading === true ? true : false}
                primaryBtnBackSideIcon={isLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                confirmationTopic="Are you sure?"
                confirmationMessage="For any reason, if you are unable to attend the interview after submitting RSVP, please inform the employer via email."
                secondaryBtnName="backBtn"
                secondaryBtnType="button"
                secondaryBtnId="interview_cancelBtnId"
                secondaryBtnCssType="defaultBtn"
                secondaryBtnLabel="Cancel"
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnId="interview_confirmAttendanceBtnId"
                primaryBtnCssType="successBtn"
                primaryBtnLabel="Confirm Attendance"
                confirmationType='success'
            />

            {/* RSVP as "I can't Attend" confirmation popup */}
            <CustomDropdownConfirmationBox
                show={Rejectmodal.isOpen}
                cancelIconclick={() => { setRejectModal({ isOpen: false, value: '' }); setApproveResponse(false); setRejectResponse(false) }}
                onSecondaryBtnClick={() => { setRejectModal({ isOpen: false, value: '' }); setApproveResponse(false); setRejectResponse(false) }}
                onFormSubmit={(values) => onSubmitRSVPResponse(false, values.dropDownSelection)}
                isSecondaryBtnDisabled={isLoading === true ? true : false}
                isPrimaryBtnDisabled={isLoading === true ? true : false}
                primaryBtnBackSideIcon={isLoading === true ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                topic="Are you sure?"
                message="Please select the reason why you can't attend the interview."
                secondaryBtnName="backBtn"
                secondaryBtnType="button"
                secondaryBtnCssType="primaryAddBtn"
                secondaryBtnLabel="Cancel"
                primaryBtnName="submitBtn"
                primaryBtnType="submit"
                primaryBtnCssType="dangerBtn"
                primaryBtnLabel="I Can't Attend"
                dropdownValues={candidateInterviewRejectionReasonsArray}
                dropdownLabel='Reason'
                defaultDropDownSelection={1}
                confirmationType="success"
            />

            {/* device access error popup */}
            <CustomMessagePopUpBox
                show={showDeviceAccessErrorPopUp}
                cancelIconclick={() => { closeDeviceAccessTestPopups() }}
                onPrimaryBtnClick={() => { closeDeviceAccessTestPopups() }}
                topic="Test Failed"
                message={deviceAccessError}
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="dangerBtn"
                primaryBtnLabel="Ok!"
                isExternalLinkAvailable={true}
                externalLinkLable="Learn how to enable permissions"
                externalLink="https://hiretrace.io/documentation/interviews/browser-permissions"
                confirmationType='danger'
            />
            {/* waiting */}
            <CustomCandidateWaitingToJoinInterviewPopUpBox
                show={isWaitingToJoinInterview}
                topic="Waiting"
                message={'We have informed interviewers that you are waiting to join. Stay in this window until they admit you.'}
            />

            {/* device access success popup */}
            <CustomMessagePopUpBox
                show={showDeviceAccessSuccessPopUp}
                cancelIconclick={() => { closeDeviceAccessTestPopups() }}
                onPrimaryBtnClick={() => { closeDeviceAccessTestPopups() }}
                topic="Test Successful"
                message="No issues detected. Both Microphone and Camera work properly."
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnId="interview_okBtnId"
                primaryBtnCssType="successBtn"
                primaryBtnLabel="Ok!"
                is_a_success_message={true}
                confirmationType='success'
            />
            {/* interview not started popup */}
            <CustomMessagePopUpBox
                show={showInterviewIsNotStartedPopUp}
                cancelIconclick={() => setShowInterviewIsNotStartedPopUp(false)}
                onPrimaryBtnClick={() => setShowInterviewIsNotStartedPopUp(false)}
                topic="Interview hasn't started yet"
                message="Interviewers haven't started the interview yet. When they start the interview, you will receive an email notification (emails might come to your spam folder)."
                primaryBtnName="submitBtn"
                primaryBtnId="interview_okBtnId"
                primaryBtnType="button"
                primaryBtnCssType="successBtn"
                primaryBtnLabel="Ok!"
                confirmationType='danger'
            />
            {/* Error during joining the interview */}
            <CustomMessagePopUpBox
                show={showInterviewOverPopUp}
                cancelIconclick={() => setShowInterviewOverPopUp()}
                onPrimaryBtnClick={() => setShowInterviewOverPopUp()}
                topic="Interview hasn't started yet or interview expired"
                message="Please Check your Schedule. If you have any concerns please contact the employer via email."
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="dangerBtn"
                primaryBtnLabel="Ok!"
                confirmationType='danger'
            />
            {/* Error during joining the interview when reviewer end the meeting*/}
            <CustomMessagePopUpBox
                show={showJoinMeetingEndedDueToInterviewerEndMeetingPopup}
                cancelIconclick={() => closeJoinInterviewErrorPopup()}
                onPrimaryBtnClick={() => closeJoinInterviewErrorPopup()}
                topic="Interview Ended"
                message="Interviewer has ended the interview. Please contact the employer via email for further details."
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="dangerBtn"
                primaryBtnLabel="Ok!"
                confirmationType='danger'
            />
            {/* Error during joining the interview */}
            <CustomMessagePopUpBox
                show={showJoinInterviewErrorPopUp}
                cancelIconclick={() => closeJoinInterviewErrorPopup()}
                onPrimaryBtnClick={() => closeJoinInterviewErrorPopup()}
                topic="Something went Wrong !"
                message="Error occured when connecting you to the interview. Please contact support@hiretrace.io ."
                primaryBtnName="submitBtn"
                primaryBtnType="button"
                primaryBtnCssType="dangerBtn"
                primaryBtnLabel="Ok!"
                confirmationType='danger'
            />

            {/*  external meeting link shared msg popup  */}
            {
                <CustomMessagePopUpBox
                    show={showMeetingEndedDueToExternalLinkPopup}
                    cancelIconclick={() => { alternativeMeetingSharedAcknowledgement() }}
                    onPrimaryBtnClick={() => { alternativeMeetingSharedAcknowledgement() }}
                    topic="An Alternative Meeting URL Shared"
                    message={"Interviewers have shared an alternative meeting url to conduct the interview. So click 'Ok!' to refresh the page and get updated interview joining details."}
                    primaryBtnName="submitBtn"
                    primaryBtnType="button"
                    primaryBtnCssType="successBtn"
                    primaryBtnLabel="Ok!"
                    confirmationType='danger'
                />
            }

            {showInterviewody && joinMeetingData ?
                <div>
                    <ThemeProvider theme={darkTheme}>
                        <MeetingProvider>
                            <InterviewBody sessionid={id} joinMeetingData={joinMeetingData} interviewDetails={interviewDetails} handleMeetingErrors={openJoinInterviewErrorPopup} handleAlternativeLinkShared={triggerAlternativeMeetingSharedAcknowledgementPopup} />
                        </MeetingProvider>
                    </ThemeProvider>
                </div>
                :
                <div>

                    {!interviewDetails ?
                        <div>
                            <div className={styles.overlay}></div>
                            <div className={`${styles.spanner} ${styles.show}`}>
                                <div className={styles.loader}></div>
                                <p className={styles.loaderText}>Please Wait...</p>
                            </div>
                        </div>
                        :
                        <div>

                            <Row className={styles.bodyRow}>
                                <Col xs={12} md={6} className={styles.submissionFormView}>
                                    <Container>
                                        <Col lg={{ span: 10, offset: 1 }}>
                                            <Col className={styles.companySecondaryHeaderBox}>

                                            </Col>
                                            <Col>
                                                <p className={styles.pageTopic}>Job Interview</p>
                                            </Col>

                                            <div className={styles.assessmentContent}>
                                                <p className={styles.nameTagValue}>You have been invited to a job interview from {interviewDetails.company_name}.</p>
                                            </div>

                                            <div className={styles.dividerDiv}></div>
                                            <div className={styles.descktopView}>
                                            <Col className="mt-5">
                                                <Row className={styles.Guidelines}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Date and Time</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_dateAndTimeId" >{moment.tz(interviewDetails.schedule.date_time, interviewDetails.schedule.time_zone).format('llll') + ' ( ' + interviewDetails?.schedule.time_zone + ' )'} </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className={styles.Guidelines}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Interview Type</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_interviewTypeId" >{(interviewDetails.interview_type === 1) ? 'In-person interview' : (interviewDetails.interview_type === 2) ? 'Online interview via invitation' : 'Online video interview'} </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col>
                                                <Row className={styles.Guidelines}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>{(interviewDetails.interview_type === 1) ? 'Location' : 'Media'}</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_mediaId"  >{(interviewDetails.interview_type === 1 && interviewDetails.interview_location_address) ? interviewDetails.interview_location_address : interviewDetails.interview_type === 2 ? 'Third party tool ' : 'HireTrace interview portal'} </p>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className="mb-4">
                                                <Row className={styles.Guidelines}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Interview Guidelines</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_interviewGuidelinesId" >{interviewDetails.interview_guidelines_to_candidate ? interviewDetails.interview_guidelines_to_candidate : 'Not available'} </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="mb-4 p-0">
                                                <div className={styles.dividerDiv}></div>
                                            </Col>
                                            <Col>
                                                <Row className={styles.Guidelines}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Candidate RSVP Status</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <div className={styles.responseStateDiv}>{rsvpResponse.responseState === true && rsvpResponse.responseType === 1 ? <Alert variant="success" className={styles.badgeType} id="interview_attendingStatusId" >Attending</Alert> : rsvpResponse.responseState === true && rsvpResponse.responseType === -1 ? <Alert variant="danger" className={styles.badgeTypeDanger} id="interview_notAttendingStatusId" >Not Attending</Alert> : rsvpResponse.responseState === false && rsvpResponse.responseType === 0 ? <Alert variant="warning" className={styles.badgeTypeWarning} id="interview_pendingStatusId" >Pending</Alert> : null}</div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {rsvpResponse.responseState === true && rsvpResponse.responseType === -1 &&
                                                <Col >
                                                    <Row className={styles.Guidelines}>
                                                        <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                            <p className={styles.nameTag}>Candidate RSVP Reason</p>
                                                        </Col>
                                                        <Col className={styles.guidelineFistcol}>
                                                            <div className={styles.responseStateDiv}>: {rsvpResponse.message}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            </div>
                                            <div className={styles.mobileView}>
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Date and Time</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_dateAndTimeId" >{moment.tz(interviewDetails.schedule.date_time, interviewDetails.schedule.time_zone).format('llll') + ' ( ' + interviewDetails?.schedule.time_zone + ' )'} </p>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Interview Type</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_interviewTypeId" >{(interviewDetails.interview_type === 1) ? 'In-person interview' : (interviewDetails.interview_type === 2) ? 'Online interview via invitation' : 'Online video interview'} </p>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>{(interviewDetails.interview_type === 1) ? 'Location' : 'Media'}</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_mediaId"  >{(interviewDetails.interview_type === 1 && interviewDetails.interview_location_address) ? interviewDetails.interview_location_address : interviewDetails.interview_type === 2 ? 'Third party tool ' : 'HireTrace interview portal'} </p>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Interview Guidelines</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_interviewGuidelinesId" >{interviewDetails.interview_guidelines_to_candidate ? interviewDetails.interview_guidelines_to_candidate : 'Not available'} </p>
                                                    </Col>
                                                </Row>
                                                <div className={styles.dividerDiv}></div>
                                            
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Candidate RSVP Status</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <div className={styles.responseStateDiv}>{rsvpResponse.responseState === true && rsvpResponse.responseType === 1 ? <Alert variant="success" className={styles.badgeType} id="interview_attendingStatusId" >Attending</Alert> : rsvpResponse.responseState === true && rsvpResponse.responseType === -1 ? <Alert variant="danger" className={styles.badgeTypeDanger} id="interview_notAttendingStatusId" >Not Attending</Alert> : rsvpResponse.responseState === false && rsvpResponse.responseType === 0 ? <Alert variant="warning" className={styles.badgeTypeWarning} id="interview_pendingStatusId" >Pending</Alert> : null}</div>
                                                    </Col>
                                                </Row>
                                            {rsvpResponse.responseState === true && rsvpResponse.responseType === -1 &&
                                                <Col >
                                                    <Row className={styles.Guidelines}>
                                                        <Col className={styles.guidelineFistcol}>
                                                            <p className={styles.nameTag}>Candidate RSVP Reason</p>
                                                        </Col>
                                                        <Col className={styles.guidelineFistcol}>
                                                            <div className={styles.responseStateDiv}>: {rsvpResponse.message}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            }
                                            </div>
                                            


                                            {(((rsvpResponse.responseState === true && rsvpResponse.responseType === 1) || (isStarted == 'true' || isStarted == true)) && ((interviewDetails.interview_type === 0) || (interviewDetails.interview_type === 2))) &&


                                                <div className='ms-0 ps-0'>
                                                    {interviewDetails.interview_type === 2 &&
                                                        <div className='ms-0 ps-0'>
                                                            <Col className={styles.mainMeetingCol}>
                                                                <Row className='ms-2 ps-0 mt-3'>
                                                                    <CustomAlertBox
                                                                        alertBodyText="Interviewers have shared an external video meeting link to conduct the interview. Please use below link to join the interview."
                                                                        alertType="defaultAlert"
                                                                    />
                                                                </Row>

                                                            </Col>

                                                            <Col className={styles.mainMeetingCol}>
                                                                <Row className={styles.Guidelines}>
                                                                    <p className={styles.nameTag}>Interview Meeting Link : </p>
                                                                    <div className={styles.externalLinkNameTag}>
                                                                        <a target="_blank" className={styles.externalLinkkValue} href={interviewDetails.external_meeting_details.external_meeting_url}> {interviewDetails.external_meeting_details.external_meeting_url} &nbsp;<i class="fas fa-external-link-alt ml-2"></i> </a>
                                                                    </div>

                                                                </Row>
                                                            </Col>
                                                            {interviewDetails.interview_type === 2 && interviewDetails.external_meeting_details.external_meeting_details &&
                                                                <Col className={styles.mainMeetingCol}>
                                                                    <Row className={styles.Guidelines}>
                                                                        <p className={styles.nameTag}>Meeting Descriptions : </p>
                                                                        {/* <p className='ml-3'>{interviewDetails.external_meeting_details.external_meeting_details ? interviewDetails.external_meeting_details.external_meeting_details : 'Not available'} </p> */}
                                                                        <div className={styles.guidelinecontentCol}>{interviewDetails.external_meeting_details.external_meeting_details ? interviewDetails.external_meeting_details.external_meeting_details : 'Not available'} </div>
                                                                    </Row>
                                                                </Col>
                                                            }

                                                        </div>}

                                                    {/* internal video interview tool */}
                                                    {interviewDetails.interview_type === 0 &&
                                                        <div>
                                                            <Col className='mt-4 mb-4 text-center'>
                                                                <Row className={styles.controlButtonsRow}>
                                                                    <Col sm={6} xs={6} className={styles.bottomButtonLeftCol}>
                                                                        <CustomButton
                                                                            buttonType="submit"
                                                                            label="Test Audio/Video"
                                                                            id="interview_testAudioBtnId"
                                                                            type="defaultBtn"
                                                                            handleClick={() => CheckDeviceAccess(false)}
                                                                            backicon={(loadingDeviceTest) ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                            disabled={loadingJoinInterview || loadingDeviceTest}
                                                                        />
                                                                    </Col>
                                                                    <Col sm={6} xs={6} className={styles.bottomButtonRightCol}>
                                                                        <CustomButton
                                                                            buttonType="submit"
                                                                            label="Join Interview"
                                                                            id="interview_joinInterviewBtnId"
                                                                            type="primaryBtn"
                                                                            handleClick={() => CheckDeviceAccess(true)}
                                                                            backicon={(loadingJoinInterview) ? <i className="fas fa-circle-notch fa-spin"></i> : null}
                                                                            disabled={loadingJoinInterview || loadingDeviceTest}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                        </div>}
                                                </div>
                                            }
                                            {(rsvpResponse.responseState === false && (!(isStarted == 'true' || isStarted == true) || (interviewDetails.interview_type === 1) || (interviewDetails.interview_type === 2))) &&
                                                <div className='text-center mb-5'>
                                                    <Row className={styles.controlButtonsRow}>
                                                        <Col>
                                                            <CustomAlertBox
                                                                alertType="defaultAlert"
                                                                alertBodyText="Please confirm your participation (RSVP) to the job interview as soon as possible."
                                                            />
                                                        </Col>

                                                    </Row>
                                                    <Row className={styles.controlButtonsSecondRow}>
                                                        <Col className="mb-2">
                                                            <div className={styles.dividerDiv}></div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={6} xs={6} className={styles.bottomButtonLeftCol}>
                                                            <CustomButton
                                                                buttonType="submit"
                                                                label="No, I Can't Attend"
                                                                id="interview_noICantAttendBtnId"
                                                                type="defaultBtn"
                                                                disabled={rejectResponse}
                                                                frontIcon={<i className="fas fa-times"></i>}
                                                                // backicon={isLoading === true && <i className="fas fa-circle-notch fa-spin"></i>} 
                                                                handleClick={() => { changeReject(); onSelectNotAttendBtn() }}
                                                            />
                                                        </Col>
                                                        <Col sm={6} xs={6} className={styles.bottomButtonRightCol}>
                                                            <CustomButton
                                                                buttonType="submit"
                                                                label="Yes, I Attend"
                                                                id="interview_yesIAttendBtnId"
                                                                type="primaryBtn"
                                                                disabled={approveResponse}
                                                                frontIcon={<i className="fas fa-check"></i>}

                                                                // backicon={isLoading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                                                                handleClick={() => { changeApprove(); onSelectAttendTab() }}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <div className={styles.submitTextCol}>
                                                        <p className={styles.signupBottomMessage}>By clicking the &quot;Yes, I Attend&quot; button, you&lsquo;re agreeing to accept</p>
                                                        <p className={styles.termsPara}><a href="https://hiretrace.io/privacy-policy" className={styles.termsLink} target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://hiretrace.io/terms-and-conditions" className={styles.termsLink} target="_blank" rel="noreferrer">Terms of Use.</a></p>
                                                    </div>
                                                </div>
                                            }

                                        </Col>
                                    </Container>
                                </Col>

                                <Col xs={12} md={6} className={styles.descriptionBox}>
                                    <Col xs={12} className={styles.descriptionInnerBox}>
                                        <Col className={styles.companyPrimaryHeaderBox}>

                                            <Col className="text-center">
                                                {interviewDetails.company_logo_url ?
                                                    <Col xs={{ span: 10, offset: 1 }}>
                                                        <Image src={interviewDetails.company_logo_url} className={styles.companyLogo} alt={interviewDetails.company_name + " logo"} />
                                                    </Col>
                                                    :
                                                    <p className={styles.companyName}>{interviewDetails.company_name}</p>
                                                }
                                                {/* <p className={styles.companyName}>{interviewDetails?.company_name}</p> */}

                                            </Col>
                                            <div className={styles.descktopView}>
                                            <Col className="mt-3">
                                                <Row className={styles.GuidelinesRow}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Candidate Name</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_candidateNameId">{interviewDetails?.candidate_name} </p>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            <Col className='ms-0'>
                                                <Row className={styles.GuidelinesRow}>
                                                    <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Position</p>
                                                    </Col>
                                                    <Col lg={1} md={1} xs={1} className={styles.nameTag}>:</Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_positionId">{interviewDetails.position}</p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </div>
                                        <div className={styles.mobileView}>
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Candidate Name</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_candidateNameId">{interviewDetails?.candidate_name} </p>
                                                    </Col>
                                                </Row>
                                                <Row className={styles.Guidelines}>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTag}>Position</p>
                                                    </Col>
                                                    <Col className={styles.guidelineFistcol}>
                                                        <p className={styles.nameTagValue} id="interview_positionId">{interviewDetails.position}</p>
                                                    </Col>
                                                </Row>
                                        </div>
                                        </Col>

                                        <Col >

                                            {/* video interview via HireTrace  */}
                                            {(interviewDetails.interview_type === 0 && !interviewDetails.is_external_meeting_enabled) &&  // if online interview
                                                <Container className={styles.subTopicMainBody}>
                                                    <div className={styles.guidlineTopicDiv}>
                                                        <div className={styles.pageGuidlineTopic}><p>Interview Guideline</p></div>
                                                    </div>
                                                    <div className={styles.guidlinecontentMaindiv}>
                                                        <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Read and understand the interview guidelines given by the company (check left side).</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>2.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>To avoid missing the interview, mark it in your calender.</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>3.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>After you submit the RSVP response as "Yes, I Attend", you will see two options: "Check Audio/Video" and "Join Interview". </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>4.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Use "Check Audio/Video" option and test your browser compatebility before the interview time.</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>5.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>You will receive an email notification when interview starts (emails might come to your spam folder).  </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>6.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>When you recieve the interview started notification, click on "Join Interview" button to connect with the interviewers. (You don't need an account in HireTrace to join the interview). </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>7.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Use a laptop or a desktop computer with a camera to take the interview and make sure to have a stable internet connection.</div>
                                                    </div>


                                                    <div className={styles.guidlineTopicDiv}>

                                                        <div className={styles.pageGuidlineTopic}>
                                                            <p>Important</p>
                                                        </div>

                                                    </div>
                                                    <Row className={styles.noPadding}>
                                                        <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.GuidelinesImportant}>
                                                                <div className={styles.guidlinecontentMaindiv}>
                                                                    <div className={styles.guidelinecontentCol}>For any reason, if you are unable to attend the interview (after marking rsvp as "Yes, I Attend"),  please inform the employer via <a href={`mailto:${interviewDetails.scheduled_by.email}`} className={styles.guidelineLinks}>{interviewDetails.scheduled_by.email}.</a></div>
                                                                </div>
                                                                <div className={styles.guidlinecontentdiv}>
                                                                    <div className={styles.guidelinecontentCol}>If you have any questions related to the interview, please reach to employer via <a href={`mailto:${interviewDetails.scheduled_by.email}`} className={styles.guidelineLinks}>{interviewDetails.scheduled_by.email}.</a></div>
                                                                </div>
                                                                <div className={styles.guidlinecontentdiv}>
                                                                    <div className={styles.guidelinecontentCol}>If you face any technical issues, please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </Row>
                                                </Container>
                                            }
                                            {/* video interview via a external tool */}
                                            {((interviewDetails.interview_type === 0 || interviewDetails.interview_type === 2) && interviewDetails.is_external_meeting_enabled) &&  // if online interview
                                                <Container className={styles.subTopicMainBody}>
                                                    <div className={styles.guidlineTopicDiv}>
                                                        <div className={styles.pageGuidlineTopic}><p>Interview Guideline</p></div>
                                                    </div>
                                                    <div className={styles.guidlinecontentMaindiv}>
                                                        <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Read and understand the interview guidelines given by the company (check left side).</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>2.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>To avoid missing the interview, mark it in your calender.</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>3.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>After you submit the RSVP response as "Yes, I Attend", you will see meeting joining details. </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>4.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Use the  "Interview Meeting Link" to join with interviewers.</div>
                                                    </div>



                                                    <div className={styles.guidlineTopicDiv}>

                                                        <div className={styles.pageGuidlineTopic}>
                                                            <p>Important</p>
                                                        </div>

                                                    </div>
                                                    <Row className={styles.noPadding}>
                                                        <div className={styles.guidlinecontentdiv}>
                                                            <div className={styles.GuidelinesImportant}>
                                                                <div className={styles.guidlinecontentMaindiv}>
                                                                    <div className={styles.guidelinecontentCol}>For any reason, if you are unable to attend the interview (after marking rsvp as "Yes, I Attend"),  please inform the employer via <a href={`mailto:${interviewDetails.scheduled_by.email}`} className={styles.guidelineLinks}>{interviewDetails.scheduled_by.email}</a>.</div>
                                                                </div>
                                                                <div className={styles.guidlinecontentdiv}>
                                                                    <div className={styles.guidelinecontentCol}>If you have any questions related to the interview, please reach to employer via <a href={`mailto:${interviewDetails.scheduled_by.email}`} className={styles.guidelineLinks}>{interviewDetails.scheduled_by.email}.</a></div>
                                                                </div>
                                                                <div className={styles.guidlinecontentdiv}>
                                                                    <div className={styles.guidelinecontentCol}>If you face any technical issues, please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </Row>
                                                </Container>
                                            }


                                            {(interviewDetails.interview_type === 1) &&  // if in-person interview
                                                <Container className={styles.subTopicMainBody}>
                                                    <div className={styles.guidlineTopicDiv}>
                                                        <div className={styles.pageGuidlineTopic}><p>Interview Guideline</p></div>
                                                    </div>
                                                    <div className={styles.guidlinecontentMaindiv}>
                                                        <div className={styles.guidelinecontentCol}>1.&nbsp;&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Read and understand the interview guidelines given by the company (check left side).</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>2.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>To avoid missing the interview, mark it in your calender.</div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>3.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Please be at the interview location at least before 15mins. </div>
                                                    </div>
                                                    <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.guidelinecontentCol}>4.&nbsp;&nbsp;</div>
                                                        <div className={styles.guidelinecontentCol}>Take the interview on a laptop or a desktop computer with a camera, and make sure you have a stable internet connection.</div>
                                                    </div>



                                                    <div className={styles.guidlineTopicDiv}>

                                                        <div className={styles.pageGuidlineTopic}>
                                                            <p>Important</p>
                                                        </div>

                                                    </div>
                                                    <Row className={styles.noPadding}>
                                                        <div className={styles.guidlinecontentdiv}>
                                                        <div className={styles.GuidelinesImportant}>
                                                                <div className={styles.guidlinecontentMaindiv}>
                                                                    <div className={styles.guidelinecontentCol}>For any reason, if you are unable to attend the interview (after marking rsvp as "Yes, I Attend"),  please inform the employer via <a href={`mailto:${interviewDetails.scheduled_by.email}`} className={styles.guidelineLinks}>{interviewDetails.scheduled_by.email}</a>.</div>
                                                                </div>
                                                                <div className={styles.guidlinecontentdiv}>
                                                                    <div className={styles.guidelinecontentCol}>If you have any questions related to the interview, please reach to employer via <a href={`mailto:${interviewDetails.scheduled_by.email}`} className={styles.guidelineLinks}>{interviewDetails.scheduled_by.email}</a>.</div>
                                                                </div>
                                                                <div className={styles.guidlinecontentdiv}>
                                                                    <div className={styles.guidelinecontentCol}>If you face any technical issues, please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                    </Row>
                                                </Container>
                                            }
                                             <Col className={styles.footerCreditBox}>
                                            <div className={styles.footerCreditBoxInner}>
                                                <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                            </div>
                                        </Col>
                                        </Col>

                                       

                                    </Col>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Interview
