import React from 'react';
import styles from './customTextArea.module.scss';
import {Form} from 'react-bootstrap';

export const CustomTextArea = (props) => {
    return (
        <div>
            <Form.Group controlId="exampleForm.ControlTextarea1">
                {props.label && 
                    <Form.Label className={styles.textareaLabel}>{props.label}</Form.Label>
                }
                <Form.Control as="textarea" name={props.name} value={props.values} onChange={props.handleChange} rows={3} className={styles.textareaBox} isInvalid={props.isInvalid} placeholder={props.placeholder} maxLength={props.maxLength} />
                <Form.Control.Feedback className={styles.errorMessage} type="invalid">{props.errorMessage}</Form.Control.Feedback>
                {props.maxLength &&
                    <Form.Text className={styles.remainingCount}><b>{props.remainingCount}</b>/{props.maxLength}</Form.Text>
                }
                
            </Form.Group>
        </div>
    )
}
