import React, { useRef, useState, useEffect } from 'react';
import { Card, Col, Row, Image, Container, Form } from 'react-bootstrap';
import styles from './interviewBody.module.scss';
import { Formik , ErrorMessage} from 'formik';
import CommonErrors from '../../shared/commonErrors';
import {
    BackgroundBlurProvider,
    useBackgroundBlur,
    useMeetingManager,
    MicSelection,
    SecondaryButton,
    CameraSelection,
    SpeakerSelection,
    PreviewVideo,
    PrimaryButton,
    Presenter,
    LeaveMeeting,
    AudioInputControl,
    VideoInputBackgroundBlurControl,
    VideoInputControl,
    AudioOutputControl,
    IconButton,
    Phone,
    Modal,
    ModalHeader,
    ModalBody,
    ModalButtonGroup,
    ModalButton,
    ContentShareControl,
    LocalVideo,
    useLocalVideo,
    useRemoteVideoTileState,
    RemoteVideo,
    ContentShare,
    useContentShareState,
    useMeetingStatus,
    useAttendeeStatus,
    useRosterState,
    RosterGroup,
    RosterAttendee,
    Attendees,
    Badge,
    Laptop,
    Chat,
    useAudioVideo
} from 'amazon-chime-sdk-component-library-react';
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import ReactGA from 'react-ga';
import { boolean } from 'yup';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CustomAlertBox } from '../../shared/customAlertBox';
import { CustomTextArea } from '../../shared/customTextArea';
import { CustomMessagePopUpBox } from '../../shared/customMessagePopUpBox';
import { CustomButton } from '../../shared/customButton';
import ChatBox from '../../shared/chatBox';
import * as yup from 'yup';
import { candidateGetMeetingEndedReason, requestToAdmitInterview } from '../../../services/util/interviews';
import { backgroundColor } from 'styled-system';
import { CustomCandidateWaitingToJoinInterviewPopUpBox } from '../../shared/CustomCandidateWaitingToJoinInterviewPopUpBox';
var moment = require("moment");


const InterviewBody = (props) => {

    const { tiles, tileIdToAttendeeId,attendeeIdToTileId } = useRemoteVideoTileState();
    let { sharingAttendeeId, isLocalUserSharing } = useContentShareState();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();
    const meetingManager = useMeetingManager();
    const meetingStatus = useMeetingStatus();
    const handle = useFullScreenHandle();
    const [meetingStep, setMeetingStep] = useState(3);

    let [isMeetingComponentLoading, setIsMeetingComponentLoading] = useState(false);
    let [localAttendeeId, setLocalAttendeeId] = useState();
    let [selectedAttendeeId, setSelectedAttendeeId] = useState();
    let [joinMeetingLoading, setJoinMeetingLoading] = useState(false);
    let [showMeetingEndedMsgPopup, setShowMeetingEndedMsgPopup] = useState(false);
    let [selectedTileID, setSelectedTileID] = useState(null);
    let [showLeaveMeetingConfirmationPopup, setShowLeaveMeetingConfirmationPopup] = useState(false);
    let [isVideoIconClicked, setIsVideoIconClicked] = useState(false);

    const audioVideo = useAudioVideo();
    let [chat_message, setChatMessage] = useState([]);
    let [numberOfMessages, setNumberOfMessages] = useState(0);
    let [applicationMeetingStatus, setAppicationMeetingStatus] = useState(0);
    let { videoEnabled } = useAttendeeStatus(selectedAttendeeId);
    const { roster } = useRosterState();
    const attendees = Object.values(roster);
    const [rosterType, setRosterType] = useState(1);
    const [charCount, setCharCount] = useState(0);
    const [showReloadModal,setShowReloadModal] = useState(false);
    const reloadTimeoutRef = useRef(null);
    const messageTimeoutRef = useRef(null);

    
    let [isWaitingToJoinInterview,setIsWaitingToJoinInterview] = useState(false);
    let [connectionIssueMessage, setConnectionIssueMessage] = useState(0);
    
    const [show, setShow] = useState(true);

    const showPreviewVideo = () => {
        setShow(!show);
        
    };
    const getInitials = (name) => {
        const nameArray = name.split(' ');
        const initials = nameArray.slice(0, 2).map(n => n[0]).join('');
        return initials.toUpperCase();
    };
    const attendeeItems = attendees.map((attendee, index) => {
        const { chimeAttendeeId, name, externalUserId } = attendee;
        let string = externalUserId;
        let result = string.split(/-(.+)/);
        let value = result[1];

        return (
            (!(isVideoEnabled && (chimeAttendeeId === localAttendeeId))) && (
                !attendeeIdToTileId[chimeAttendeeId] && (
                    <div className={styles.relativeDiv}>
                        <div className={styles.nameLogo}>
                            {getInitials(value)}
                        </div>
                        <div className={styles.absoluteDiv}>
                            <RosterAttendee key={chimeAttendeeId} videoEnabled={false} attendeeId={chimeAttendeeId} name={value} subtitle={chimeAttendeeId===localAttendeeId?'Candidate':'Reviewer'} />
                        </div>
                    </div>
                )
            )
        );
    });
    let numberOfAttendees = attendees.length;
    let [showControls, setShowControls] = useState(false);
    let [fullScreenMode, setFullScreenMode] = useState(false);


    const triggerMeetingEndedAcknowledgementPopup = async () => {

        let respond = await candidateGetMeetingEndedReason(props.sessionid);
        if (respond.success === true && respond.data.ended_due_to_external_meeting ) {
            props.handleAlternativeLinkShared();
        } else {
            setShowMeetingEndedMsgPopup(true);
        }
        
    }

    if (applicationMeetingStatus !== meetingStatus) {
        setAppicationMeetingStatus(meetingStatus)
        if (meetingStatus === 1) {
            setMeetingStep(3);
            setJoinMeetingLoading(false)
        }
        if (meetingStatus === 3) {
            triggerMeetingEndedAcknowledgementPopup();
        }
    }

    const schema = yup.object({
        message: yup.string().trim().required().max(1000, 'Maximum 1000 characters allowed')
    });

    const initiateMeeting = async () => {

        if(props.joinMeetingData && props.joinMeetingData.meetingInfo && props.joinMeetingData.attendeeInfo && props.joinMeetingData.attendeeInfo.Attendee && props.joinMeetingData.attendeeInfo.Attendee.AttendeeId){
            await setLocalAttendeeId(props.joinMeetingData.attendeeInfo.Attendee.AttendeeId.toString())

            const meetingSessionConfiguration = new MeetingSessionConfiguration(props.joinMeetingData.meetingInfo,props.joinMeetingData.attendeeInfo);
            await meetingManager.join(meetingSessionConfiguration);
            setMeetingStep(2);
            setIsMeetingComponentLoading(false);
        }else{
            props.handleMeetingErrors();
        }
        
    };


    const startMeeting = async () => {
        let value = await requestToAdmitInterview(props.joinMeetingData.meetingInfo.Meeting.ExternalMeetingId);
        if(value.hostApproved){
            setJoinMeetingLoading(true);
            await meetingManager.start();
            setIsWaitingToJoinInterview(false);
            }
            else{
                setIsWaitingToJoinInterview(true);
                setTimeout(startMeeting, 5000); // Delay of 5 seconds before re-running the function 
            }
    }

    //for video on/off handler
    const handleVideoToggle = () => {
        setIsVideoIconClicked(true);
        toggleVideo();
    }

    useEffect(() => {
        if (!isVideoEnabled && meetingStatus === 1 && !isVideoIconClicked) {
            toggleVideo();
        }
    }, [isVideoEnabled, meetingStatus]);

    const leaveMeeting = async () => {
        setIsMeetingComponentLoading(true);
        await meetingManager.leave();
        setIsMeetingComponentLoading(false);
        setShowLeaveMeetingConfirmationPopup(false)
        window.location.reload();
    }

    const triggerLeaveMeetingConfirmationPopup = () => {
        setShowLeaveMeetingConfirmationPopup(true);
    }

    const meetingEndedMsgAcknowledgement = () => {
        window.location.reload();
    }



    const updateSelectedAtendeeStatus = (tileid) => {
        if (!tileid || (tileid === '-2')) {
            setSelectedAttendeeId(null)
        }
        else if (tileid === '-1') {
            setSelectedAttendeeId(localAttendeeId)
            setSelectedAttendeeId(localAttendeeId)
        }
        else {
            const id = (tileIdToAttendeeId[tileid]).toString();
            setSelectedAttendeeId(id)
            setSelectedAttendeeId(id)
        }
    }

    const viewControls = () => {
        setShowControls(true)
        let timeout;
        (() => {
            clearTimeout(timeout);
            timeout = setTimeout(() => setShowControls(false), 3500);
        })();
    }

    const sendChatData = (text, { resetForm }) => {
        const mess = {
            action: 'outgoing',
            cmd: "TEXT",
            data: text.message,
            createdDate: moment().format('hh:mm A'),
            senderName: props.interviewDetails.candidate_name
        }
        audioVideo.realtimeSendDataMessage('chat', JSON.stringify(mess));
        resetForm({ message: '' });
        setChatMessage([...chat_message, mess])
    }

    //chat message receive
    const receiveChatData = (mess) => {
        if (rosterType !== 3) {
            let count = numberOfMessages + 1;
            setNumberOfMessages(count);
        }
        const data = JSON.parse(mess.text());
        data.action = 'incoming';
        setChatMessage([...chat_message, data]);
    }

    const toggleFullScreen = () => {
        const elements = document.getElementById("toggle-container");

        // Check if the browser is in fullscreen mode
        const isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;

        if (isFullScreen) {
            // Exit fullscreen
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        } else {
            // Enter fullscreen
            if (elements.requestFullscreen) {
                elements.requestFullscreen();
            } else if (elements.webkitRequestFullscreen) {
                elements.webkitRequestFullscreen();
            }
        }
    }
    const getAttendeeId = (titleId) => {
        const attendeeId = (tileIdToAttendeeId[titleId]).toString();
        return (attendeeId)
    }
    const getLoaclAttedndeedName = (attendeeId) => {
        const clearAttendeeId = attendeeId.split('#')[0];
        const  string = roster[clearAttendeeId];
        let result = string? string.externalUserId.split(/-(.+)/): '';
        return result[1];
    }
    const getAttendeeName = (titleId) => {
        const attendeeId = (tileIdToAttendeeId[titleId])?.toString();
        const  string = roster[attendeeId];
        let result = string? string.externalUserId.split(/-(.+)/): '';
        return result[1];
    }

    useEffect(() => {
        initiateMeeting();
    }, [])

    const chatContainerRef = useRef(null);

    const scrollDownChat = () => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        scrollDownChat();
    }, [chat_message]);



    //chat data useEffect
    useEffect(() => {
        //oinMeeting()
        if (audioVideo) {
            audioVideo.realtimeSubscribeToReceiveDataMessage(
                'chat',
                receiveChatData
            );

            return () => {
                audioVideo.realtimeUnsubscribeFromReceiveDataMessage('chat');
            }
        }

    }, [audioVideo, chat_message, numberOfMessages, rosterType])

    useEffect(() => {
        if (rosterType === 3) {
            setNumberOfMessages(0);
        }
    }, [rosterType]);

    useEffect(() => {
        setSelectedAttendeeId(null);
        setSelectedTileID(null)
    }, [videoEnabled]);

    useEffect(() => {
        // // Check if the browser is in fullscreen mode
        const isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;

        if (isFullScreen) {
            setFullScreenMode(true);
        } else {
            setFullScreenMode(false);

        }
    }, [document.fullscreenElement || document.webkitFullscreenElement])

    useEffect(()=>{
        if(tiles){
            const index = tiles[0];
            updateSelectedAtendeeStatus(index);
            setSelectedTileID(index);
        }
    },[tiles])

    //chat button clicked method
    const handleClickChatButton = async () => {
        await setRosterType(2);
        setNumberOfMessages(0);
        scrollDownChat();
    }

    useEffect(() => {
        if (meetingStatus === 7) {
            setConnectionIssueMessage(10);
            setShowReloadModal(true);
            updateSelectedAtendeeStatus(null);
            setSelectedTileID(null);
            
            // Clear any existing timeouts
            if (reloadTimeoutRef.current) {
                clearTimeout(reloadTimeoutRef.current);
            }
            if (messageTimeoutRef.current) {
                clearTimeout(messageTimeoutRef.current);
            }
            
            // Change Message after 1 minute
            messageTimeoutRef.current = setTimeout(() => {
                setConnectionIssueMessage(20);
            }, 60000); // 1 min

            // Set a timeout to reload the page after 2 minutes
            reloadTimeoutRef.current = setTimeout(() => {
                window.location.reload();
            }, 120000); // 2 min
        } else if (meetingStatus === 1) {
            setShowReloadModal(false);
            // Clear the timeouts if meetingStatus changes to 1
            if (reloadTimeoutRef.current) {
                clearTimeout(reloadTimeoutRef.current);
                reloadTimeoutRef.current = null;
            }
            if (messageTimeoutRef.current) {
                clearTimeout(messageTimeoutRef.current);
                messageTimeoutRef.current = null;
            }
        }

        // Clean up the timeouts when the component unmounts or meetingStatus changes
        return () => {
            if (reloadTimeoutRef.current) {
                clearTimeout(reloadTimeoutRef.current);
            }
            if (messageTimeoutRef.current) {
                clearTimeout(messageTimeoutRef.current);
            }
        };
    }, [meetingStatus]);

    return (
        <BackgroundBlurProvider>
        <div>
            {
                showLeaveMeetingConfirmationPopup && (
                    <Modal size="md" onClose={() => setShowLeaveMeetingConfirmationPopup(false)} rootId="modal-root">
                        <ModalHeader title="End Meeting" />
                        <ModalBody>
                            <p>You can leave meeting by clicking "Leave Meeting" button.</p>
                            <ModalButtonGroup
                                primaryButtons={[
                                    <div>
                                        <ModalButton onClick={leaveMeeting} label="Leave Meeting" variant="primary" />
                                    </div>
                                ]}
                                secondaryButtons={[
                                    <ModalButton
                                        onClick={() => setShowLeaveMeetingConfirmationPopup(false)}
                                        label="Cancel"
                                        variant="secondary"
                                    />
                                ]}
                            />
                        </ModalBody>
                    </Modal>
                )
            }
             {/* Connection Issue loading popup */}

             {showReloadModal && (
                <div>
                    <div className={styles.connctionIssueModal}>
                        <div className={styles.innerConnctionIssueModal}>
                                <p className={connectionIssueMessage === 20? styles.connctionIssueTitle20 : styles.connctionIssueTitle10 }>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.3119 10C16.6802 10.4263 17.9624 11.1191 19.08 12.05M22.5799 8.49997C19.6575 5.92394 15.8956 4.50262 11.9999 4.50262C11.3949 4.50262 10.7931 4.5369 10.1972 4.60447M8.52979 15.61C9.54499 14.8888 10.7595 14.5013 12.0048 14.5013C13.2501 14.5013 14.4646 14.8888 15.4798 15.61M12 19.5H12.01M1.19336 8.70076C2.52697 7.47869 4.06839 6.47975 5.75851 5.76306M4.73193 12.243C6.12934 11.012 7.84172 10.1302 9.73265 9.73393M15.6983 15.7751C14.6792 14.9763 13.3952 14.5 11.9999 14.5C10.5835 14.5 9.28172 14.9908 8.25537 15.8116M3 3L21 21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    <span>Connection Issue.</span>
                                </p>
                            <p className={styles.connctionIssueText}>{connectionIssueMessage === 20? 'There is a connectivity issue. Please check your internet connection and refresh the page.':connectionIssueMessage === 10?'The interview has detected network connection issues, Please hold on.':'' }</p>
                            <div class={styles.connectionLoader}></div>
                        </div>
                    </div>
                </div>
             )  
             }

            {/* interview ended msg popup */}
            {
                <CustomMessagePopUpBox
                    show={showMeetingEndedMsgPopup}
                    cancelIconclick={() => { meetingEndedMsgAcknowledgement() }}
                    onPrimaryBtnClick={() => { meetingEndedMsgAcknowledgement() }}
                    topic="Interview Ended"
                    message={"This interview has been ended by interviewers"}
                    primaryBtnName="submitBtn"
                    primaryBtnType="button"
                    primaryBtnCssType="successBtn"
                    primaryBtnLabel="Ok!"
                />
            }
            {/* waiting */}
            <CustomCandidateWaitingToJoinInterviewPopUpBox
                show={isWaitingToJoinInterview}
                topic="Waiting"
                message={'We have informed interviewers that you are waiting to join. Stay in this window until they admit you.'}
            />

            <div>
                {/* if the meeting details still loading */}
                {isMeetingComponentLoading === true ? 
                    (<div>
                        <div className={styles.overlay}></div>
                        <div className={`${styles.spanner} ${styles.show}`}>
                            <div className={styles.loader}></div>
                            <p className={styles.loaderText}>Please Wait...</p>
                        </div>
                    </div>)
                    :
                    (<Col className={meetingStep === 3 ? styles.mainColumnBodyInterview : styles.mainColumnBody}>

                        <Card className={styles.mainBodyCard}>
                            <Card.Body className={styles.candidateInterviewBody}>
                                <Row className={styles.rowFlex}>
                                    {/* common area (show interview details in left side) */}
                                    <Col md={3} xl={4} className={styles.interviewDetailsView}>
                                        <Card className={styles.interviewDetailsSide}>
                                            <Card.Body className={styles.interviewDetailsSidebar}>

                                                <Row className={styles.companyLogo}>
                                                    {props.interviewDetails.company_logo_url ?
                                                        <Col  xs={{ span: 10 }}>
                                                            <Image src={props.interviewDetails.company_logo_url}  alt={props.interviewDetails.company_name + " logo"} />
                                                        </Col>
                                                        :
                                                        <p className={styles.companyName}>{props.interviewDetails.company_name}</p>
                                                    }


                                                </Row>
                                                <Card.Title className={styles.interviewDetailssTopic}>
                                                    Interview Details
                                                </Card.Title>
                                                <Card.Subtitle className={styles.interviewDetailsSubTopic}>
                                                    Vacancy Details
                                                </Card.Subtitle>
                                                <Row className='d-flex flex-row'> 
                                                    <div className={styles.topicLabelDiv}><p className={styles.topicLabels}>Company </p></div>
                                                    <div className={styles.secondTopicLabelDiv}><p className={styles.topicValues}>: {props.interviewDetails.company_name}</p></div>
                                                </Row>
                                                <Row className='d-flex flex-row'> 
                                                    <div className={styles.topicLabelDiv}><p className={styles.topicLabels}>Position </p></div>
                                                    <div className={styles.secondTopicLabelDiv}><p className={styles.topicValues}>: {props.interviewDetails.position}</p></div>
                                                </Row>
                                                <Card.Subtitle className={styles.interviewDetailsSubTopic}>
                                                    Candidate Details
                                                </Card.Subtitle>
                                                <Row className='d-flex flex-row'> 
                                                    <div className={styles.topicLabelDiv}><p className={styles.topicLabels}>Name</p></div>
                                                    <div className={styles.secondTopicLabelDiv}><p className={styles.topicValues}>: {props.interviewDetails.candidate_name}</p></div>
                                                </Row>
                                                <Row className='d-flex flex-row'> 
                                                    <div className={styles.topicLabelDiv}><p className={styles.topicLabels}>Email</p></div>
                                                    <div className={styles.secondTopicLabelDiv}><p className={styles.topicValues}>: {props.interviewDetails.candidate_email}</p></div>
                                                </Row>

                                                <Card.Subtitle className={styles.interviewDetailsSubTopic}>
                                                    Interview Guidelines
                                                </Card.Subtitle>
                                                <Row>
                                                    {/* <Col><p className={styles.topicLabels}>{props.interviewDetails.interview_guidelines_to_candidate} </p></Col> */}
                                                    <Col><p className={styles.topicValues}>{props.interviewDetails.interview_guidelines_to_candidate ? props.interviewDetails.interview_guidelines_to_candidate : 'Not available' } </p></Col>
                                                </Row>
                                            </Card.Body>
                                                <Col className={styles.footerCreditBox}>
                                                    <div className={styles.footerCreditBoxInner}>
                                                        <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                                    </div>
                                                </Col>
                                        </Card>
                                    </Col>
                                    {/* Meeting area */}
                                    {meetingStep === 2 || (meetingStep === 2 && applicationMeetingStatus === 0) ?
                                    (<Col md={9} xl={8} className={styles.orderfirstMain}>
                                        <Col sm={{ span: 10, offset: 1 }} className={styles.cardBodySettings}>
                                            <Card className={styles.confirgureDeviceSettingsBox}>
                                                <Card.Body >
                                                    <Row><p className={styles.stepDescription}>Choose your audio and video options</p></Row>
                                                    <Row>
                                                        <Col md={6}>
                                                            <div className={styles.settingDiv}>
                                                                <MicSelection />
                                                            </div>
                                                            <div>
                                                                <SpeakerSelection />
                                                            </div>
                                                            <div>
                                                                <CameraSelection />
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <Card.Title className={styles.configurationsTitle}>
                                                                Video Preview
                                                            </Card.Title>
                                                            <div className={styles.previewVideoBody}>
                                                                <div className={styles.vidioPrivewRelativeDiv}>
                                                                            {show ? (
                                                                                <PreviewVideo />
                                                                            ) : (
                                                                                <div className={styles.noPreviewViedo}>
                                                                                    <svg width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M5 5C3.34315 5 2 6.34315 2 8V16C2 17.6569 3.34315 19 5 19H14C15.3527 19 16.4962 18.1048 16.8705 16.8745M17 12L20.6343 8.36569C21.0627 7.93731 21.2769 7.72312 21.4608 7.70865C21.6203 7.69609 21.7763 7.76068 21.8802 7.88238C22 8.02265 22 8.32556 22 8.93137V15.0686C22 15.6744 22 15.9774 21.8802 16.1176C21.7763 16.2393 21.6203 16.3039 21.4608 16.2914C21.2769 16.2769 21.0627 16.0627 20.6343 15.6343L17 12ZM17 12V9.8C17 8.11984 17 7.27976 16.673 6.63803C16.3854 6.07354 15.9265 5.6146 15.362 5.32698C14.7202 5 13.8802 5 12.2 5H9.5M2 2L22 22" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>
                                                                                </div>
                                                                            )}
                        
                                                                    <div className={styles.absoluteControls}>
                                                                        <VideoInputBackgroundBlurControl  onClick={showPreviewVideo}/>
                                                                        <AudioInputControl />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                    <div className={styles.btnRowSettings}>
                                                                        <SecondaryButton label="Leave Interview" disabled={isMeetingComponentLoading === true && meetingStep === 2 ? true : false} icon={isMeetingComponentLoading === true && meetingStep === 2 ? <i className="fas fa-spinner fa-spin"></i> : <LeaveMeeting />} onClick={leaveMeeting} />
                                                                        <PrimaryButton label='Join Interview' disabled={joinMeetingLoading === true && meetingStep === 2 && applicationMeetingStatus === 0 ? true : false} icon={joinMeetingLoading === true && meetingStep === 2 && applicationMeetingStatus === 0 ? <i className="fas fa-spinner fa-spin"></i> : <Presenter />} onClick={startMeeting} />
                                                                    </div>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Col>) 
                                    : meetingStep === 3 && applicationMeetingStatus === 1 ?  
                                            (<Col md={9} xl={8} className={styles.orderfirst}>
                                                <Card className={styles.mainBodyCard}>
                                                    <Card.Body className={styles.candidateInterviewBody1}>
                                                        <Row className={styles.innterCnadidateInterviewBody}>
                                                            <Col className={styles.interviewDataPartCol}>
                                                                <Row className={styles.interviewDataPart}>
                                                                    <Col className={styles.mainVideoBackgroundCol}>

                                                                        <Card className={styles.mainVideoBackgroundCard}>

                                                                            <Card.Body className={styles.mainVideoBackground} onMouseMove={() => viewControls()}>
                                                                                {/* Full screen handle div */}
                                                                                <div className={styles.fullscreenContainer} id="toggle-container">
                                                                                    {(!!selectedTileID && (selectedTileID !== '-1') && (selectedTileID !== '-2')) &&
                                                                                        // && videoEnabled === true
                                                                                        <RemoteVideo tileId={selectedTileID} name={getAttendeeName(selectedTileID)} />
                                                                                    }
                                                                                    {(!!selectedTileID && (selectedTileID == '-1') && isVideoEnabled) &&
                                                                                    
                                                                                        <LocalVideo  nameplate={getLoaclAttedndeedName(localAttendeeId)}  />
                                                                                    }
                                                                                    {(!!selectedTileID && (selectedTileID == '-2') && !!sharingAttendeeId) &&
                                                                                        <ContentShare nameplate={getLoaclAttedndeedName(sharingAttendeeId)} />
                                                                                    }
                                                                                    {fullScreenMode === true &&
                                                                                        <Col xs={10} lg={4} className={showControls === true ? `${styles.buttonRowOutlineHover} ${styles.showcontrols}` : styles.buttonRowOutlineHover}>
                                                                                            <Row className={styles.actionBtnInnerRow}>
                                                                                                <AudioInputControl className={styles.controlInputIcon} />
                                                                                                <AudioOutputControl className={styles.controlInputIcon} />
                                                                                                <VideoInputBackgroundBlurControl onClick={handleVideoToggle} className={styles.controlInputIcon} />
                                                                                                <div onClick={() => { setSelectedTileID('-2') }} className={styles.contentShareDiv}><ContentShareControl /></div>
                                                                                                <span className={styles.controlInputIcon}>
                                                                                                    <IconButton
                                                                                                        icon={<i className="fas fa-compress-arrows-alt"></i>}
                                                                                                        className={styles.videoTileBtn}
                                                                                                        onClick={() => { toggleFullScreen(); setFullScreenMode(false) }}
                                                                                                    />
                                                                                                </span>
                                                                                            </Row>
                                                                                        </Col>
                                                                                    }
                                                                                </div>

                                                                            </Card.Body>
                                                                        </Card>
                                                                    </Col>
                                                                    <Col xs={12} sm={3} className={styles.remoteVideosCol} id={styles.rosterid}>
                                                                        <Row className="m-0">
                                                                            <Col xs={12} sm={12} className={rosterType === 1? styles.remoteVideosInnerCol:styles.remoteVideosInnerCol2 }>
                                                                                { rosterType === 1 ?
                                                                                    <div className={styles.tiles}>
                                                                                        <div><p className={styles.titleTxt}>Attendees&nbsp;{numberOfAttendees > 0 && `(${numberOfAttendees})`}</p></div>
                                                                                        <Row xs={12} className={styles.tileOuterBox}>
                                                                                            {tiles.map(tileId => (selectedTileID !== tileId) && (<Col xs={6} sm={6} onClick={() => { updateSelectedAtendeeStatus(tileId); setSelectedTileID(tileId); }} className={styles.oneRemoteVideoScreenSize}>
                                                                                                <div className={styles.relativeDiv}>
                                                                                                    <RemoteVideo tileId={tileId} key={tileId} />
                                                                                                    <div className={styles.absoluteDiv}>
                                                                                                        <RosterAttendee attendeeId={getAttendeeId(tileId)} key={getAttendeeId(tileId)} name={getAttendeeName(tileId) } subtitle={getAttendeeId(tileId)===localAttendeeId?'Candidate':'Reviewer'} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>))}
                                                                                            {((selectedTileID !== '-1') && isVideoEnabled) &&
                                                                                                (<Col xs={6} sm={6} className={styles.oneRemoteVideoScreenSize} onClick={() => { updateSelectedAtendeeStatus('-1'); setSelectedTileID('-1'); }}>
                                                                                                <div className={styles.relativeDiv}>
                                                                                                    <LocalVideo />
                                                                                                    <div className={styles.absoluteDiv}>
                                                                                                        <RosterAttendee attendeeId={localAttendeeId} videoEnabled={true} key={localAttendeeId} name={getLoaclAttedndeedName(localAttendeeId)} subtitle='Candidate' />
                                                                                                    </div>
                                                                                                </div>
                                                                                                </Col>)
                                                                                            }
                                                                                            {(sharingAttendeeId && (selectedTileID !== '-2')) &&
                                                                                                (<Col xs={6} sm={6} className={styles.oneRemoteVideoScreenSize} onClick={() => { updateSelectedAtendeeStatus('-2'); setSelectedTileID('-2'); }}>
                                                                                                    <div className={styles.relativeDiv}>
                                                                                                    <ContentShare nameplate={getLoaclAttedndeedName(sharingAttendeeId)}/>
                                                                                                </div>
                                                                                                </Col>)
                                                                                            }
                                                                                        </Row>
                                                                                        <RosterGroup style={{ backgroundColor: 'none' }} className={styles.attendeesbox}>{attendeeItems}</RosterGroup>
                                                                                    </div>
                                                                                        : rosterType === 2 &&
                                                                                        <div><div className="ms-3"><p className={styles.titleTxt}>Chat</p></div>
                                                                                        <div>
                                                                                            <div className={styles.chatContainer} ref={chatContainerRef}>
                                                                                                {chat_message.length > 0 &&
                                                                                                    chat_message.map((values, index) => (
                                                                                                        <ChatBox
                                                                                                            variant={values.action}
                                                                                                            senderName={values.senderName}
                                                                                                            timestamp={values.createdDate}
                                                                                                            key={index}
                                                                                                            content={values.data}
                                                                                                        />
                                                                                                    ))
                                                                                                }
                                                                                            </div>
                                                                                            <div className={styles.textBoxContainer}>
                                                                                                <Formik
                                                                                                    validationSchema={schema}
                                                                                                    initialValues={{}}
                                                                                                    onSubmit={(values, resetForm) => sendChatData(values, resetForm)}>
                                                                                                    {({
                                                                                                        handleSubmit,
                                                                                                        handleChange,
                                                                                                        errors,
                                                                                                        touched,
                                                                                                        values
                                                                                                    }) => (
                                                                                                        <div>
                                                                                                            <Form noValidate onSubmit={handleSubmit}>
                                                                                                                <div className={styles.sendMsgDiv}>
                                                                                                                    <textarea
                                                                                                                        className={styles.messageArea}
                                                                                                                        id="messageTextarea"
                                                                                                                        placeholder="Type a new message"
                                                                                                                        name="message"
                                                                                                                        onChange={handleChange}
                                                                                                                        value={values.message || ''}
                                                                                                                    />
                                                                                                                    <button type="submit" className={styles.sendButton}>
                                                                                                                        <svg
                                                                                                                            width="27"
                                                                                                                            height="24"
                                                                                                                            viewBox="0 0 27 24"
                                                                                                                            fill="none"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                        >
                                                                                                                            <path
                                                                                                                                d="M11.8762 12.001H5.21434M5.1118 12.3541L2.28364 20.8022C2.06146 21.4659 1.95036 21.7977 2.03009 22.0021C2.09932 22.1795 2.24802 22.3141 2.4315 22.3653C2.64278 22.4242 2.96191 22.2806 3.60015 21.9934L23.8418 12.8847C24.4648 12.6043 24.7763 12.4642 24.8726 12.2694C24.9562 12.1003 24.9562 11.9018 24.8726 11.7326C24.7763 11.5379 24.4648 11.3977 23.8418 11.1174L3.5931 2.00549C2.95678 1.71914 2.63861 1.57597 2.42754 1.6347C2.24423 1.68571 2.09555 1.8199 2.02608 1.99703C1.94608 2.201 2.05598 2.53213 2.27579 3.19438L5.11259 11.7412C5.15034 11.855 5.16922 11.9119 5.17667 11.97C5.18328 12.0216 5.18321 12.0739 5.17647 12.1255C5.16887 12.1836 5.14984 12.2405 5.1118 12.3541Z"
                                                                                                                                stroke="#3CBFDB"
                                                                                                                                strokeWidth="2.42249"
                                                                                                                                strokeLinecap="round"
                                                                                                                                strokeLinejoin="round"
                                                                                                                            />
                                                                                                                        </svg>
                                                                                                                    </button>
                                                                                                                </div>
                                                                                                                <ErrorMessage name="message" component="div" className={styles.errorMsg} />
                                                                                                            </Form>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </Formik>
                                                                                            </div>
                                                                                        </div>
                                                                                        </div>

                                                                                }

                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                                <Row className={styles.actionBtnRow}>
                                                                    <Col xs={12} lg={9} className={styles.buttonRowOutline}>
                                                                        <Row className={styles.actionBtnInnerRow}>
                                                                            <AudioInputControl className={styles.controlInputIcon} />
                                                                            <AudioOutputControl className={styles.controlInputIcon} />
                                                                            <VideoInputBackgroundBlurControl onClick={handleVideoToggle} className={styles.controlInputIcon} />
                                                                            <div onClick={() => { setSelectedTileID('-2') }} className={styles.contentShareDiv}><ContentShareControl /></div>
                                                                            <span className={styles.controlInputIcon}>
                                                                                <IconButton icon={<Phone/>} onClick={triggerLeaveMeetingConfirmationPopup} className={styles.endMeetingBtn} />
                                                                            </span>
                                                                            <div  className={styles.videoTileBtn}>
                                                                            <span className={styles.controlInputIcon}>
                                                                            <IconButton
                                                                                icon={<i className="fas fa-expand-arrows-alt"></i>}
                                                                                onClick={() => {toggleFullScreen(); setFullScreenMode(true)}}
                                                                                className={styles.videoTileBtn}
                                                                            />
                                                                            </span>
                                                                            </div>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col xs={12} sm={4} lg={3} className={styles.rosterColumn}>
                                                                        <div className={styles.buttonOutline}>
                                                                            <div className={styles.rosterBtn} >
                                                                            {rosterType === 1 ?
                                                                                <IconButton
                                                                                    icon={<Attendees />}
                                                                                    selected={boolean('Selected', true)}
                                                                                    onClick={() => {
                                                                                        setRosterType(1);
                                                                                      }}
                                                                                      
                                                                                />
                                                                                :
                                                                                <IconButton
                                                                                    icon={<Attendees />}
                                                                                    onClick={() => {
                                                                                        setRosterType(1);
                                                                                        setNumberOfMessages(0);
                                                                                      }}
                                                                                      
                                                                                />
                                                                            }
                                                                            <p className={styles.rosterBtnText}>Attendees</p>
                                                                            </div>
                                                                            <div className={styles.rosterBtn}>
                                                                            {rosterType === 2 ?
                                                                                <IconButton
                                                                                    icon={
                                                                                       <div className={styles.selectedBtn}>
                                                                                        <svg width="20" height="20" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M12.2261 18.5652L8.56787 22.2692C8.05758 22.7858 7.80244 23.0442 7.58314 23.0624C7.39288 23.0782 7.20659 23.0017 7.08233 22.8568C6.9391 22.6897 6.9391 22.3266 6.9391 21.6004V19.7448C6.9391 19.0933 6.4056 18.6219 5.761 18.5275V18.5275C4.2009 18.299 2.97567 17.0737 2.74715 15.5136C2.70947 15.2564 2.70947 14.9495 2.70947 14.3355V8.81059C2.70947 6.8119 2.70947 5.81256 3.09844 5.04916C3.44059 4.37765 3.98654 3.8317 4.65804 3.48956C5.42144 3.10059 6.42079 3.10059 8.41947 3.10059H17.2224C19.2211 3.10059 20.2204 3.10059 20.9838 3.48956C21.6553 3.8317 22.2013 4.37765 22.5434 5.04916C22.9324 5.81256 22.9324 6.8119 22.9324 8.81059V13.8068M22.9324 26.8923L20.3434 25.0923C19.9794 24.8392 19.7975 24.7127 19.5994 24.623C19.4236 24.5434 19.2388 24.4855 19.0491 24.4505C18.8353 24.4111 18.6136 24.4111 18.1704 24.4111H16.0328C14.7003 24.4111 14.0341 24.4111 13.5252 24.1518C13.0775 23.9237 12.7136 23.5597 12.4855 23.1121C12.2261 22.6031 12.2261 21.9369 12.2261 20.6045V17.6135C12.2261 16.281 12.2261 15.6148 12.4855 15.1059C12.7136 14.6582 13.0775 14.2942 13.5252 14.0662C14.0341 13.8068 14.7003 13.8068 16.0328 13.8068H22.6945C24.0269 13.8068 24.6932 13.8068 25.2021 14.0662C25.6498 14.2942 26.0137 14.6582 26.2418 15.1059C26.5011 15.6148 26.5011 16.281 26.5011 17.6135V20.8424C26.5011 21.9509 26.5011 22.5052 26.32 22.9424C26.0786 23.5254 25.6154 23.9885 25.0324 24.23C24.5952 24.4111 24.0409 24.4111 22.9324 24.4111V26.8923Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                        </svg>
                                                                                        </div>
                                                                                        }
                                                                                    selected={boolean('Selected', true)}
                                                                                    onClick={() => {handleClickChatButton()}}
                                                                                />
                                                                                :
                                                                                <IconButton
                                                                                icon={
                                                                                    <div className={styles.unSelectedBtn}>
                                                                                    <svg width="20" height="20" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M12.2261 18.5652L8.56787 22.2692C8.05758 22.7858 7.80244 23.0442 7.58314 23.0624C7.39288 23.0782 7.20659 23.0017 7.08233 22.8568C6.9391 22.6897 6.9391 22.3266 6.9391 21.6004V19.7448C6.9391 19.0933 6.4056 18.6219 5.761 18.5275V18.5275C4.2009 18.299 2.97567 17.0737 2.74715 15.5136C2.70947 15.2564 2.70947 14.9495 2.70947 14.3355V8.81059C2.70947 6.8119 2.70947 5.81256 3.09844 5.04916C3.44059 4.37765 3.98654 3.8317 4.65804 3.48956C5.42144 3.10059 6.42079 3.10059 8.41947 3.10059H17.2224C19.2211 3.10059 20.2204 3.10059 20.9838 3.48956C21.6553 3.8317 22.2013 4.37765 22.5434 5.04916C22.9324 5.81256 22.9324 6.8119 22.9324 8.81059V13.8068M22.9324 26.8923L20.3434 25.0923C19.9794 24.8392 19.7975 24.7127 19.5994 24.623C19.4236 24.5434 19.2388 24.4855 19.0491 24.4505C18.8353 24.4111 18.6136 24.4111 18.1704 24.4111H16.0328C14.7003 24.4111 14.0341 24.4111 13.5252 24.1518C13.0775 23.9237 12.7136 23.5597 12.4855 23.1121C12.2261 22.6031 12.2261 21.9369 12.2261 20.6045V17.6135C12.2261 16.281 12.2261 15.6148 12.4855 15.1059C12.7136 14.6582 13.0775 14.2942 13.5252 14.0662C14.0341 13.8068 14.7003 13.8068 16.0328 13.8068H22.6945C24.0269 13.8068 24.6932 13.8068 25.2021 14.0662C25.6498 14.2942 26.0137 14.6582 26.2418 15.1059C26.5011 15.6148 26.5011 16.281 26.5011 17.6135V20.8424C26.5011 21.9509 26.5011 22.5052 26.32 22.9424C26.0786 23.5254 25.6154 23.9885 25.0324 24.23C24.5952 24.4111 24.0409 24.4111 22.9324 24.4111V26.8923Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                                                    </svg>
                                                                                    </div>
                                                                                    }
                                                                                    badge={numberOfMessages !== 0 && <Badge value={numberOfMessages} className={styles.attendeesNoBadge} />}
                                                                                    onClick={() => {handleClickChatButton()}}
                                                                                />
                                                                            }
                                                                             <p className={styles.rosterBtnText}>Chat</p>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>)
                                    : 
                                    
                                    (applicationMeetingStatus ==2 || applicationMeetingStatus ==6)  ?
                                    
                                     (<Card className={styles.interviewBodyErrorMsgBox}> 

                                        <p className={styles.interviewBodyErrorMsgText}>Something went wrong. Try refreshing the page</p>
                                    
                                    </Card>) :null
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                       
                    </Col>)
                }
            </div>
        </div>
        </BackgroundBlurProvider>
    )
}

export default InterviewBody;
