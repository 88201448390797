import React from 'react';
import styles from './submitted.module.scss'


export default function RefreeFeedbackSubmited() {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.content}>
                    Thank you for your feedback.
                </p>

            </div>

        </div>


    )
}
