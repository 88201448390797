import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, ProgressBar, Container, Image } from 'react-bootstrap';
import styles from './refreeFeedback.module.scss';
import * as yup from 'yup';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { CustomButton } from '../shared/customButton'
import { Redirect, useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import { CustomTextField } from '../shared/customTextField';
import { CustomTextArea } from '../shared/customTextArea';
import { getRefeeDetails, submitRefreeFeedback } from '../../services/util/referenceCheck';


export default function RefreeFeedback() {
    const history = useNavigate();

    let [primaryColor, setPrimaryColor] = useState('');
    const [isDataLoading, setDataLoading] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [generalData, setGeneralData] = useState({});
    const [refreesDetails, setRefreesDetails] = useState({});
    const URL = useLocation();
    const session_id = URL.pathname.split('/')[2];
    const referee_id = URL.pathname.split('/')[3];

    const schema = yup.object({
        feedback: yup.string().trim().required('Feedback is a required field')
    });

    const getInitialDetails = async () => {
        setDataLoading(true);
        const response = await getRefeeDetails(session_id, referee_id);
        setGeneralData(response.data.general_data);
        setRefreesDetails(response.data.referee_record);
        setPrimaryColor(response.data.general_data.primary_color ? response.data.general_data.primary_color : '#24859a')
        setDataLoading(false);
        if(response.data.referee_record.feedback){
            history("/reference-feedback-request-expired")
        }else if(response.data.pipline_state === -10 || response.data.reference_session_state === -30 ){
            history("/reference-feedback-request-expired")
        }
        setLoading(false);
    };
    const addRefreeFeedback = async (values) => {
        setLoading(true);
        const response = await submitRefreeFeedback(session_id,refreesDetails._id,values.feedback);
        if (response.success) {
            setLoading(false);
            history("/reference-feedback-request-submited")
        }
        else {
            setLoading(false);
            history("/reference-feedback-request-expired")
        }
    };

    useEffect(() => {
        getInitialDetails();
    }, []);

    //set company selected color as the primary color
    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty(
            "--selected-primary-color", primaryColor
        );
    }, [primaryColor]);


    return (
        <div>
            {isDataLoading ?
                <div>
                    <div className={styles.overlay}></div>
                    <div className={`${styles.spanner} ${styles.show}`}>
                        <div className={styles.loader}></div>
                        <p className={styles.loaderText}>Please Wait...</p>
                    </div>
                </div> :
                <div>
                    <Row className={styles.bodyRow}>
                        <Col xs={12} md={6} className={styles.submissionFormView}>
                            <div className={styles.mainContainer}>
                                <Container>
                                    <Col lg={{ span: 10, offset: 1 }}>

                                        <Col className={styles.companySecondaryHeaderBox}></Col>

                                    </Col>
                                    <Col>
                                        <p className={styles.pageTopic}>We need your feedback</p>
                                    </Col>
                                    <div>
                                        <p className={styles.assessmentMain}>Hello {refreesDetails.reference_name}, {generalData.candidate_name} has applied for a job vacancy in our company called {generalData.company_name} and we wanted to ask your feedback about the candidate as a part of our hiring process. Please be kind enough to enter your feedback and click "Submit" button below.</p>
                                    </div>


                                    <Container className={styles.SecContainer}>
                                        <div className={styles.containerInnerWrap}>
                                            <Formik
                                                validationSchema={schema}
                                                onSubmit={(values) => {
                                                    addRefreeFeedback(values);
                                                }}
                                                initialValues={{}}
                                            >
                                                {({
                                                    errors,
                                                    handleChange,
                                                    handleSubmit,
                                                    setFieldValue,
                                                    submitCount,
                                                    resetForm,
                                                    values
                                                }) => (
                                                    <Form noValidate onSubmit={handleSubmit}>
                                                        <div className={styles.formDiatils}>
                                                            <p className={styles.submitFormTitle}>Your Name</p>
                                                            <p className={styles.submitFormValues}>{refreesDetails.reference_name}</p>
                                                        </div>
                                                        <div className={styles.formDiatils}>
                                                            <p className={styles.submitFormTitle}>Your Email</p>
                                                            <p className={styles.submitFormValues}>{refreesDetails.reference_email}</p>
                                                        </div>
                                                        <div className='mt-2'>
                                                            <div>
                                                                <p className={styles.submitFormTitle}>Your Feedback About the Candidate</p>
                                                                <p className={styles.submitFormSubTitle}>Your feedback will not be visible to the candidate</p>
                                                            </div>
                                                            <CustomTextArea
                                                                name="feedback"
                                                                placeholder="Type your feedback"
                                                                values={values.feedback}
                                                                errorMessage={errors.feedback}
                                                                handleChange={handleChange}
                                                                isInvalid={submitCount > 0 && !!errors.feedback}
                                                            />
                                                        </div>
                                                        <div className="mt-4 mb-3">
                                                            <CustomButton
                                                                buttonType="submit"
                                                                label="Submit"
                                                                type="primarySmallBtn"
                                                                disabled={isLoading ? true : false}
                                                                backicon={isLoading === true && <i className="fas fa-circle-notch fa-spin"></i>}
                                                                handleClick={() => { handleSubmit() }}
                                                            />
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>


                                        </div>
                                    </Container>

                                </Container>
                            </div>

                        </Col>

                        <Col xs={12} md={6} className={styles.descriptionBox}>
                            <Col xs={12} className={styles.descriptionInnerBox}>
                                <Col className={styles.companyPrimaryHeaderBox}>

                                    <Col className="text-center mt-2">
                                        {generalData?.company_logo_url ?
                                            <Col xs={{ span: 10, offset: 1 }}>
                                                <Image src={generalData?.company_logo_url} className={styles.companyLogo} alt={generalData?.company_logo_url + " logo"} />
                                            </Col>
                                            :
                                            <p className={styles.companyName}>{generalData?.company_name}</p>
                                        }
                                    </Col>
                                    <div className={styles.descktopView}>
                                    <Col className="mt-3">
                                        <Row className={styles.GuidelinesRow}>
                                            <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Candidate Name</p>
                                            </Col>
                                            <Col lg={1}>
                                            :
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>{generalData.candidate_name}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className={styles.GuidelinesRow}>
                                            <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Candidate Email</p>
                                            </Col>
                                            <Col lg={1}>
                                            :
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>{generalData.candidate_email}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className={styles.GuidelinesRow}>
                                            <Col lg={3} md={5} xs={5} className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Position</p>
                                            </Col>
                                            <Col lg={1}>
                                            :
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>{generalData.hiring_position}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    
                                </div>
                                
                                <div className={styles.mobileView}>
                                <Col className="mt-3">
                                        <Row className={styles.GuidelinesRow}>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Candidate Name</p>
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>{generalData.candidate_name}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className={styles.GuidelinesRow}>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Candidate Email</p>
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>{generalData.candidate_email}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className={styles.GuidelinesRow}>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTag}>Position</p>
                                            </Col>
                                            <Col className={styles.guidelineFistcol}>
                                                <p className={styles.nameTagValue}>{generalData.hiring_position}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </div>
                                </Col>
                                <div className={styles.guidlineTopicDiv}>

                                    <div className={styles.pageGuidlineTopic}>
                                        <p>Important</p>
                                    </div>

                                </div>
                                <Row className='mt-4 ml-1'>
                                    <div className={styles.guidlinecontentdiv}>
                                        <div className={styles.Guidelines}>
                                            <p className={styles.guidelinecontentCol}>If you want to reach out to our Hiring team, please reach us via <a href={`mailto:${generalData.contact_email}`} className={styles.guidelineLinks}>{generalData.contact_email}</a>.</p>
                                        </div>
                                    </div>
                                    <div className={styles.guidlinecontentdiv}>
                                        <div className={styles.Guidelines}>
                                            <p className={styles.guidelinecontentCol}>If you face any issues during "Submit" , please reach to <a href={`mailto:support@hiretrace.io`} className={styles.guidelineLinks}>support@hiretrace.io</a>.</p>
                                        </div>
                                    </div>

                                </Row>
                                <Col className={styles.footerCreditBox}>
                                    <div className={styles.footerCreditBoxInner}>
                                        <p>Powered By <span> <a className={styles.footerCreditLink} href="https://hiretrace.io" rel="noreferrer" target="_blank"> HireTrace</a></span></p>
                                    </div>
                                </Col>
                            </Col>         
                        </Col>



                    </Row>
                </div>
            }
        </div>
    )
}
