import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './customDropdown.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip'

export const CustomDropdown = (props) => {
    let values = props.dropdownValues;

    return (
        <Form.Group controlId="exampleForm.SelectCustom" className={`${props.classType === 'smallDropDown' ? styles.smallDropDownForm : ''} `}>
            {props.label &&
                <Form.Label
                    className={`
                    ${props.classLabel === 'defaultLabel' ? styles.defaultLabel : ''}
                    ${props.classLabel === 'disabledLabel' ? styles.disabledLabel : ''} `}>

                        {props.labelToolTipId && props.labelToolTip ?

                        <p className={styles.labelWithToolTip}> {props.label } &nbsp;
                            
                                <svg data-tip data-for={props.labelToolTipId } xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                <ReactTooltip id={props.labelToolTipId } place="right" type="dark" effect="solid" clickable={true}>
                                                            <div className={styles.toolTipBox}>{<p>{props.labelToolTip }</p>}</div>
                                                            </ReactTooltip>
                        </p> 

                        : props.label 
                        }
                    
                </Form.Label>
            }
            {props.labelDesc && 
                <Form.Label
                    className={styles.classLabelDesc}>
                    {props.labelDesc}
                </Form.Label>
            }
            
            <Form.Select disabled={props.disabled} readOnly={props.readOnly} onFocus={props.onFocus} className={`${props.classType === 'defaultDropDown' ? styles.defaultDropDown : ''} ${props.classType === 'defaultErrorDropDown' ? styles.defaultErrorDropDown : ''} ${props.classType === 'disabledDropDown' ? styles.disabledDropDown : ''} ${props.classType === 'smallDropDown' ? styles.smallDropDown : ''} `} name={props.name} onChange={props.handleOnChange} isInvalid={props.isInvalid} value={props.value}>
                {
                    values.map((value) => (
                        <option key={value.value} value={value.value}>{value.label}</option>
                    ))
                }
            </Form.Select>
            {props.errorMessage &&
                <p className={styles.errorMessage}>{props.errorMessage}</p>
            }
            
        </Form.Group>
    )
}

CustomDropdown.defaultProps = {
    classType: 'defaultDropDown',
    classLabel: 'defaultLabel',
};
