import axios from 'axios';
import { axiosInstance } from '../../api';
import { errorHandler } from '../../interceptors';
const BASE_PATH = '/reference_check';



export let getReferenceDetails = async (session_id) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_reference_check_details', {
            reference_check_session_id: session_id
        });
        return value;
    } catch (error) {
        return error
    }
}

export let submitReference = async (sessionID, referenceDetails) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/submit_reference_check_uploads', {
            reference_check_session_id: sessionID,
            referenceDetails: referenceDetails,
        })
        return value;
    } catch (error) {
        return error
    }
}
export let getRefeeDetails= async (sessionID, referee_id) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/get_reference_feedback_request_details', {
            session_id: sessionID,
            referee_id: referee_id,
        })
        return value;
    } catch (error) {
        return error
    }
}
export let submitRefreeFeedback = async (sessionID, refreeId, feedback) => {
    try {
        let value = await axiosInstance.post(BASE_PATH + '/submit_reference_feedback', {
            session_id: sessionID,
            referee_id: refreeId,
            feedback: feedback,
        })
        return value;
    } catch (error) {
        return error
    }
}