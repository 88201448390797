import React from 'react';
import styles from './submitted.module.scss'


export default function QuizWithdrew(props) {

    return (

        <div >
            <div className={styles.container}>
                <p className={styles.contentExpired}>
               You have successfully withdrawn your application.
                </p>

            </div>

        </div>


    )
}